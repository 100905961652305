<template>
	<div>
		<transition name="back-in" :duration="{ enter: 500, leave: 200 }">

			<div class="player bounceInUp" v-if="playerStatus">
				<div class="left_">

					<button type="button" class="prev" @click="skip('prev')"></button>

					<div class="toggle_actions">
						<button type="button" v-ripple class="toggle_play" v-if="!playing" @click="play"></button>
						<button type="button" v-ripple class="toggle_pause" v-if="playing" @click="pause"></button>
						<button type="button" v-ripple class="mobile_menu" :class="{'toggle_arrow': drawerPlayer}" @click="openPlayerMobileMenu">
							<span class="material-icons">keyboard_arrow_up</span>
						</button>
						<v-progress-circular
							v-if="!mobile && (playListLoading || !trackOnload)"
							:size="50"
							:width="2"
							indeterminate
							color="#ffffff">
						</v-progress-circular>
					</div>

					<button id="next-track-btn" type="button" class="next" @click="skip('next')"></button>

					<button class="next_album" v-if="playZone && playZone.length && currentFromPlay !== 'track_default'" @click="nextAlbum"></button>

					<div class="info_track">

						<div class="img" :class="{'hover': albumHover}"
						     v-if="currentAlbum.smallImage || currentAlbum.image" @mouseover="albumHover = true" @mouseleave="albumHover = false">
							<div @click="redirectToAlbum(currentAlbum.id, currentAlbum.identifier)">
								<img :src="currentAlbum.smallImage || currentAlbum.image" :alt="currentAlbum.title">
							</div>
						</div>
						<div class="title_artist">
							<div class="track_title" v-if="currentTrack">
                                <span @mouseover="albumHover = true" @mouseleave="albumHover = false"
                                      @click="redirectToAlbum(currentAlbum.id, currentAlbum.identifier)" :class="{'hover': albumHover}">
                                    {{ currentTrack.title }}
                                </span>
								<div class="bpm" v-if="currentTrack &&currentTrack.bpm !== 0">({{ currentTrack.bpm }} BPM)</div>
								<!--<span class="key" v-if="currentTrack.key || currentTrack.key !== null">-->
							</div>

							<div class="track_artist" v-if="currentTrack && currentTrack.artist">
								<span>By</span>
								<div @click="redirectToArtistPage(currentTrack.artist.id, currentTrack.artist.identifier)" class="artist_name">
									{{ currentTrack.artist.name }}
								</div>
							</div>

							<div v-else class="track_artist">-</div>
						</div>
					</div>
				</div>

				<div class="progress">
					<!-- <span class="time_current">{{ duration / 100 * progress | minutes }}</span> -->

					<!-- <v-progress-linear
						v-model="progress"
						color="#20C4F5"
					></v-progress-linear> -->

					<div class="progress_line">
						<template>

							<v-slider
                ref="slider"
								:disabled="!trackOnload"
								color="#20C4F5"
								track-color="#7481A6"
								track-fill-color="#20C4F5"
								thumb-color="#20C4F5"
								thumb-size="0"
								v-model="progress"
								step="0.1"
								@change="changeSetSeek($event)"
								@mousedown="mouseDownSetSeek($event)"
								@mouseup="mouseUpSetSeek($event)"
								min="0"
							>
							</v-slider>
						</template>
					</div>
					<!-- <span class="time_end">{{ duration | minutes }}</span> -->
				</div>

				<div class="right_box">

					<div class="time_details">
						<span>{{ duration / 100 * progress | minutes }}/{{ duration | minutes }}</span>
					</div>

					<!-- SEPARATOR -->
					<div class="separator"></div>

					<!-- SHARE -->
					<button type="button" v-ripple class="share_link" @click="createLinkTrack"></button>

					<!-- FAVORITE -->
					<div class="track_purchased" v-if="currentTrack && currentTrack.isPurchased === true"></div>
					<template v-else>
						<button v-if="currentTrack && currentTrack.isFavorite === false" @click.stop="addTrackToFavorites(currentTrack, currentAlbum)" class="like">
							<span class="material-icons">favorite_border</span>
						</button>
						<button v-if="currentTrack && currentTrack.isFavorite === true"
                    @click.stop="removeTrackFromFavorites(currentTrack, currentAlbum)"
                    class="like active">
							<span class="material-icons">favorite</span>
						</button>
					</template>

					<!-- ADD TRACK TO PLAYLIST  -->
					<button class="playlist-add">
						<span class="material-icons">playlist_add</span>
					</button>

					<!-- MORE -->
					<button class="more" @click="modalMoreIcon = !modalMoreIcon">
						<span class="material-icons">more_vert</span>
					</button>

					<!-- TOGGLE WIDTH -->
					<!-- <button @click="playerToggleWidth = !playerToggleWidth" class="toggle-width">
						<span class="material-icons">keyboard_arrow_down</span>
					</button> -->

					<!-- LOOP -->
					<!--    <button :class="[loop ? 'loop_on' : '', 'loop']" @click="toggleLoop"></button>-->

					<!-- VOLUME -->
					<!--                    <div class="volume-controls-wrapper">-->
					<!--                        <button class="volume"-->
					<!--                                :class="[muted ? 'mute' : 'unmute', '']"-->
					<!--                                @click="toggleMute"-->
					<!--                                @mouseenter="showVolume"-->
					<!--                        ></button>-->
					<!--                        <div-->
					<!--                            @mouseenter="setVolumeBarStatus(true)"-->
					<!--                            @mouseleave="setVolumeBarStatus(false)"-->
					<!--                            v-show="volumeStatus">-->
					<!--                            <vue-slider @change="updateVolume" v-bind="volumeOptions"></vue-slider>-->
					<!--                        </div>-->
					<!--                    </div>-->

					<!--QUEUE OF SONGS -->
					<!--                    <button class="play_list" @click="changePlaylistStatus(!showPlayList)"-->
					<!--                            :class="{'active': showPlayList}"></button>-->

					<!--                    <button :class="[shuffle ? 'shuffle_on' : '', 'shuffle']" @click="toggleShuffle"></button>-->

				</div>

				<v-dialog
					v-if="modalShareLink"
					v-model="modalShareLink"
					transition="dialog-bottom-transition"
					max-width="600">

					<div class="content_popup_share">
						<div class="head_popup">Share</div>
						<div class="input_box">
							<input type="text" id="input-share-track-link" v-model="shareLink">
							<button type="button" v-ripple @click="copyLinkTrack">Copy</button>
						</div>
						<button type="button" v-ripple @click="modalShareLink = false" class="close">Close</button>
					</div>
				</v-dialog>

				<v-card
					v-if="modalMoreIcon"
					v-model="modalMoreIcon"
					class="more_card"
					>
						<div class="wrap_drawer_list">
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === false"
                    @click="addTrackToFavorites(currentTrack, currentAlbum)" v-ripple class="favorite">Favorite
						</button>
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === true"
                    @click="removeTrackFromFavorites(currentTrack, currentAlbum)" v-ripple class="active favorite">Favorite
						</button>
						<button type="button" v-ripple class="share" @click="createLinkTrack">Share</button>
						<button type="button" v-ripple class="add_to_playlist" @click="dialogComingSoonVisible = true; drawerPlayer = false">Add to Playlist
						</button>
					</div>
					
				</v-card>

			</div>
		</transition>

		<template v-if="mobile">
			<v-navigation-drawer
				v-model="drawerPlayer"
				fixed
				bottom
				color="#192234">
				<div class="wrap_drawer" v-touch="{down: () => swipe('Down')}">

					<div class="wrap_drawer_player">
						<div class="action_buttons">
							<button type="button" v-ripple class="prev_track" @click="skip('prev')"></button>
							<div class="toggle_play">
								<button type="button" v-ripple class="play" v-if="!playing" @click="play"></button>
								<button type="button" v-ripple class="pause" v-if="playing" @click="pause"></button>
							</div>
							<button type="button" v-ripple class="next_track" @click="skip('next')"></button>
						</div>
						<div class="track_progress">
							<span class="time_current">{{ duration / 100 * progress | minutes }}</span>
							<v-slider
                ref="slider"
								color="#20C4F5"
								track-color="#ffffff"
								track-fill-color="#20C4F5"
								thumb-color="#20C4F5"
								thumb-size="0"
								v-model="progress"
								step="0.1"
								@change="changeSetSeek($event)"
								@mousedown="mouseDownSetSeek($event)"
								@mouseup="mouseUpSetSeek($event)"
								min="0"
							>
							</v-slider>
							<span class="time_end">{{ duration | minutes }}</span>
						</div>
					</div>
					<div class="wrap_drawer_list">
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === false"
                    @click="addTrackToFavorites(currentTrack, currentAlbum)" v-ripple class="favorite">Favorite
						</button>
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === true"
                    @click="removeTrackFromFavorites(currentTrack, currentAlbum)" v-ripple class="active favorite">Favorite
						</button>
						<button type="button" v-ripple class="share" @click="createLinkTrack">Share</button>
						<button type="button" v-ripple class="add_to_playlist" @click="dialogComingSoonVisible = true; drawerPlayer = false">Add to Playlist
						</button>
					</div>
					<!-- <button type="button" v-ripple class="close_drawer" @click="closePlayerMobileMenu">Cancel</button> -->

				</div>
			</v-navigation-drawer>
		</template>


		<dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible"></dialogNeedLogin>
		<comingSoonDialog v-if="dialogComingSoonVisible" v-model="dialogComingSoonVisible"></comingSoonDialog>
		<v-dialog v-if="dialogErrorTrack" v-model="dialogErrorTrack" max-width="600">
			<div class="need_login_dialog style_dialog">
				<h2 class="header">
					Something’s wrong
				</h2>
				<div class="cnt">
					There seems to be a problem connecting to our server<br>
					You can try again or come back at a later time
				</div>
				<div class="wrap_actions">
					<button type="button" class="cancel" @click="dialogErrorTrack = false" v-ripple>Cancel</button>
					<button type="button" class="retry" @click="reloadTrack" v-ripple>Retry</button>
				</div>
			</div>
		</v-dialog>
	</div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import dialogNeedLogin from '@/components/dialogs/needLogin';
import comingSoonDialog from '@/components/dialogs/comingSoonDialog';

// mixins
import isMobile from "@/mixins/isMobile";
import addTrackToFavorites from "@/mixins/musicLike/addTrackToFavorites";
import removeTrackFromFavorites from "@/mixins/musicLike/removeTrackFromFavorites";

export default {
	name: "playerControlBars",
	components: {
		dialogNeedLogin,
		comingSoonDialog
	},
	mixins: [
		isMobile,
		addTrackToFavorites,
		removeTrackFromFavorites,
	],
	data() {
		return {
			progress: 0,
			duration: 0,
      currentTime: 0,
			like: false,
			shareLink: '',
			modalShareLink: false,
			volumeStatus: false,
			volumeBar: false,
			volume: 0.5,
			volumeOptions: {
				direction: 'btt',
				tooltip: 'none',
				min: 0.1,
				max: 1,
				interval: 0.1
			},
			needLoginDialog: {
				action: null,
				visible: false,
			},
			dialogComingSoonVisible: false,
			dialogErrorTrack: false,
			hasScrolledToBottom: false,
			drawerPlayer: false,
			playerToggleWidth: false,
			changeProgress: false,
			albumHover: false,
			draggingSlider: false,
			modalMoreIcon: false,
		}
	},
	watch: {
		currentAlbum: {
			handler: function () {
        this.initMediaSession();
				if (this.currentAlbum && this.currentAlbum.playList) {
					this.currentAlbum.playList[0].firstTrack = true;
					this.currentAlbum.playList[this.currentAlbum.playList.length - 1].lastTrack = true;
				}
			},
			deep: true
		},
		trackList: {
			handler: function () {
        this.initMediaSession();
				if (this.trackList.length) {
					this.trackList[0].firstTrack = true;
					this.trackList[this.trackList.length - 1].lastTrack = true;
				}
			}
		},
    currentTrack: {
      handler: function () {
        this.initMediaSession();
      },
      deep: true
    },
		trackOnload(value) {
			if (value) {
				requestAnimationFrame(this.animateProgress);
			}
		},
		soundError() {
			this.dialogErrorTrack = true;
		},
		playerStatus() {
			if (!window.onkeydown) {
				window.addEventListener('keydown', this.handleKeydown);
			}
      this.initMediaSession();
		},
		playing() {
      if (!window.onkeydown) {
				window.addEventListener('keydown', this.handleKeydown);
			}
      this.initMediaSession();
		}
	},
	computed: {
		...mapGetters({
			authData: 'AUTH_DATA',
			playing: 'PLAYING',
			playerStatus: 'PLAYER_STATUS',
			currentTrack: 'CURRENT_TRACK',
			currentAlbum: 'CURRENT_ALBUM',
			trackList: 'TRACKS',
			sound: 'SOUND',
			shuffle: 'SHUFFLE',
			currentTrackIdx: 'CURRENT_TRACK_IDX',
			loop: 'LOOP',
			muted: 'MUTED',
			// duration: 'DURATION',
			showPlayList: 'SHOW_PLAY_LIST',
			discovery: 'DISCOVERY',
			currentFromPlay: 'CURRENT_FROM_PLAY',
			playZone: 'PLAYED_ZONE',
			trackOnload: 'TRACK_ONLOAD',
			playListLoading: 'FULL_ALBUM_LOADING',
			fullAlbum: 'FULL_ALBUM',
			currentPage: 'CURRENT_PAGE',
			soundError: 'SOUND_ERROR',
			changeSeek: 'SEEK',
			bufferProgress: 'BUFFER_PROGRESS',
		}),
	},
	mounted() {
    // events for mobile view
    if (this.$refs.slider) {
      this.$refs.slider.$el.addEventListener('touchstart', this.mouseDownSetSeek);
      this.$refs.slider.$el.addEventListener('touchend', this.handleTouchMove);
    }

    this.initMediaSession();

		window.addEventListener('scroll', this.onScroll);
		if (!window.onkeydown) {
			window.addEventListener('keydown', this.handleKeydown);
		}
  },
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('keydown', this.handleKeydown);
	},
	methods: {
		...mapActions({
			play: 'SET_PLAY',
			pause: 'SET_PAUSE',
			stop: 'STOP_PLAY',
			toggleShuffle: 'TOGGLE_SHUFFLE',
			toggleLoop: 'TOGGLE_LOOP',
			// nextAlbum: 'SET_NEXT_ALBUM',
			toggleMute: 'TOGGLE_MUTE',
			setSoundVolume: 'SET_SOUND_VOLUME',
			changePlaylistStatus: 'CHANGE_PLAY_LIST_STATUS',
			skipAlbum: 'SKIP_ALBUM'
		}),

		animateProgress() {
			if (this.playing && !this.changeProgress) {
				this.sound.addEventListener('timeupdate', () => {
            if (!this.draggingSlider) {
              const currentTime = this.sound.currentTime;
              this.duration = this.sound.duration;
              this.progress = (currentTime / this.duration) * 100;
            }
				});
			}
		},

		mouseDownSetSeek() {
			this.draggingSlider = true;
			this.changeProgress = true;
    },

		mouseUpSetSeek(event) {
      if (event.button === 0) {
				const sliderWidth = event.target.offsetWidth;
				const offsetX = event.offsetX;

				this.progress = (offsetX / sliderWidth) * 100;
      }

      this.draggingSlider = false;
      this.changeProgress = false;
    },

    handleTouchMove(event) {
      const offsetX = event.touches[0].clientX;
      const sliderWidth = event.target.offsetWidth;

      this.progress = (offsetX / sliderWidth) * 100;

      this.draggingSlider = false;
      this.changeProgress = false;
    },

		updateSeek(direction) {
			const seekChange = (direction === 'prev') ? -10 : 10;
			this.sound.currentTime = this.sound.currentTime + seekChange;
		},

		handleKeydown(e) {
      switch (e.keyCode) {
				case 37: // left arrow
					this.updateSeek('prev');
					break;
				case 39: // right arrow
					this.updateSeek('next');
					break;
			}
		},

		changeSetSeek(percents) {
      this.sound.currentTime = (Math.floor(percents) / 100) * this.duration;
		},

    /**
     * Initializes the Media Session API for controlling multimedia playback.
     * Sets the metadata of the current track (title, artist, album, and album artwork) and
     * action handlers such as play, pause, previoustrack, and nexttrack to provide control
     * over multimedia playback in the web application via system playback controls.
     */
    initMediaSession() {
      if ('mediaSession' in navigator) {
        const title = this.currentTrack ? this.currentTrack.title || 'No Track Title' : 'No Track Tile';
        const artist = this.currentTrack && this.currentTrack.artist ? this.currentTrack.artist.name || 'No Artist' : 'No Artist';
        const album = this.currentAlbum ? this.currentAlbum.title || 'No Album Title' : 'No Album Title';
        const artworkSrc = this.currentAlbum ? this.currentAlbum.smallImage || '' : '';

        navigator.mediaSession.metadata = new MediaMetadata({
          title: title,
          artist: artist,
          album: album,
          artwork: [
            { src: artworkSrc, sizes: '96x96',   type: 'image/png' },
            { src: artworkSrc, sizes: '128x128', type: 'image/png' },
            { src: artworkSrc, sizes: '192x192', type: 'image/png' },
            { src: artworkSrc, sizes: '256x256', type: 'image/png' },
            { src: artworkSrc, sizes: '384x384', type: 'image/png' },
            { src: artworkSrc, sizes: '512x512', type: 'image/png' },
          ]
        });

        navigator.mediaSession.setActionHandler('play', () => {
          this.$store.commit('SET_CURRENT_PLAYING', true);
          this.sound.play();
        });

        navigator.mediaSession.setActionHandler('pause', () => {
          this.$store.commit('SET_CURRENT_PLAYING', false);
          this.sound.pause();
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
          this.skip('prev');
        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
          this.skip('next');
        });

		navigator.mediaSession.setActionHandler('seekto', (details) => {
         if (details.seekTime) {
            this.sound.currentTime = details.seekTime;
           }
        });
      }
    },

		nextAlbum() {
			this.skipAlbum('next');
		},
		swipe(direction) {
			if (direction === 'Down') {
				this.drawerPlayer = false;
			}
		},
		redirectToAlbum(albumID, albumTitle) {
			this.$router.push({name: 'albumDetails', params: {name: window.toHumanFormat(albumTitle), id: albumID}});
		},
		redirectToArtistPage(artistId, artistName) {
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		skip(direction) {
      this.$store.dispatch('SKIP_TRACK', direction);
		},
		reloadTrack() {
			this.play();
		},
		createLinkTrack() {
			this.closePlayerMobileMenu();
			this.modalShareLink = true;
			let track = this.currentAlbum.playList.filter((item) => item.song.id === this.currentTrack.id);
			this.shareLink = `https://${window.location.hostname}/album/${this.currentAlbum.id}/${track[0].id}`;
		},
		copyLinkTrack() {
			navigator.clipboard.writeText(this.shareLink);
		},
		onScroll: function () {
			window.onscroll = () => {
				this.hasScrolledToBottom = document.documentElement.scrollTop + window.innerHeight + 42 >= document.documentElement.scrollHeight;
			};
		},
		openPlayerMobileMenu() {
			this.drawerPlayer = true;
		},
		closePlayerMobileMenu() {
			this.drawerPlayer = false;
		},
	},
}
</script>

<style scoped lang="scss">

</style>
